import Http from '@/utils/Http';

import { ADD_COMMENT, COMMENT_LIST } from './url';

/**
 * 发表评论
 * @param {*} params
 */
export function toPublic(params) {
  return Http.post(ADD_COMMENT, params);
}

/**
 * 获取帖子详情及评论
 * @param {*} params
 */
export function getDetailWithRemark(params) {
  return Http.get(COMMENT_LIST, params);
}
