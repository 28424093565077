<template>
  <div class="detail_con">
    <div class="content_con">
      <post-item :item="detail" />
      <div class="remark" v-show="detail.id">
        <div class="title">全部评论</div>
        <list
          v-show="remarks.length > 0"
          v-model="loading"
          :finished="isFinish"
          finished-text="没有更多了"
          @load="toLoad"
        >
          <remark-item v-for="(item, index) in remarks" :key="index" :remark="item" />
        </list>
        <div class="empty" v-show="remarks.length == 0">
          <span>暂无评论，点击写评论！</span>
        </div>
      </div>
    </div>
    <div class="input_con" v-show="detail.id">
      <div class="input-box"><input placeholder="评论千万条，友善第一条" v-model="inputTxt" /></div>
      <span @click="clickPublic">发布</span>
    </div>
  </div>
</template>

<script>
import RemarkItem from '@/components/remark/RemarkItem';
import PostItem from '@/components/BBS/List';
import { List } from 'vant';

import { toPublic, getDetailWithRemark } from '@/api/ec';

import { showToast, showLoading, hideLoading } from '@/utils/VantUtils';

import { CHECK_REAL_AUTH, GO_TO_REAL_AUTH } from '@/config/wvConfig';

export default {
  components: {
    RemarkItem,
    PostItem,
    List
  },
  data() {
    return {
      detailId: 0,
      inputTxt: '',
      detail: {},
      remarks: [],
      pageNum: 1,
      pageSize: 15,
      loading: false,
      isFinish: false
    };
  },
  methods: {
    clickPublic() {
      if (this.detailId == 0) return;

      const txt = this.inputTxt.trim();
      if (txt.length <= 0) {
        showToast('请输入评论内容!');
        return;
      }
      window.location.href = CHECK_REAL_AUTH;
    },
    toPublic() {
      const txt = this.inputTxt.trim();
      showLoading();
      const params = {
        communityId: this.detailId,
        content: txt
      };
      toPublic(params)
        .then((res) => {
          this.inputTxt = '';
          this.pageNum = 1;
          hideLoading();
          this.getDetail();
        })
        .catch((error) => {
          hideLoading();
          showToast(error.desc);
        });
    },
    getDetail() {
      showLoading();
      const params = {
        communityId: this.detailId,
        pageSize: this.pageSize,
        pageNum: this.pageNum
      };
      getDetailWithRemark(params)
        .then((res) => {
          if (this.pageNum == 1) this.remarks = [];

          this.pageNum++;
          this.loading = false;
          if (res.object.length <= this.pageSize) this.isFinish = true;

          hideLoading();
          this.detail = {
            id: this.detailId,
            commentNum: res.commentNum,
            content: res.content,
            headImg: res.headImg,
            imgUrl: res.imgUrl,
            nickName: res.nickName,
            shareNum: res.shareNum,
            supportNum: res.supportNum,
            isSupport: res.isSupport,
            userId: res.userId,
            origin: 'detail'
          };
          this.remarks = [...this.remarks, ...res.object];
        })
        .catch((error) => {
          this.loading = false;
          this.isFinish = true;
          hideLoading();
        });
    },
    toLoad() {
      // this.getDetail();
      console.log('34566677');
    }
  },
  mounted() {
    window.getRealAuth = (status) => {
      if (status == 'true') {
        this.toPublic();
        return;
      }
      showToast('请先实名！');
      setTimeout(() => {
        window.location.href = GO_TO_REAL_AUTH;
      }, 500);
    };
  },
  created() {
    const query = this.$route.query;
    if (!query.id) {
      showToast('帖子飞了！');
      return;
    }
    this.detailId = query.id;
    this.getDetail();
  }
};
</script>

<style scoped>
.detail_con {
  position: relative;
  width: 100vw;
  height: 100vh;
}

.detail_con > .content_con {
  padding-bottom: 60px;
}

.content_con > .remark {
  font-size: 14px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 16px;
}

.remark > .title {
  margin-bottom: 8px;
}

.remark > .empty {
  color: #adadad;
  font-size: 12px;
  width: 100%;
  text-align: center;
  margin-top: 100px;
}

.detail_con > .input_con {
  position: fixed;
  bottom: 0px;
  width: 100vw;
  height: 60px;
  border-top: 1px solid #d1d1d1;
  display: flex;
  align-items: center;
  background: white;
}

.input_con input {
  width: 100%;
  height: 32px;
}

.input_con > .input-box {
  overflow: hidden;
  flex: 1;
  height: 32px;
  border-radius: 16px;
  border: 1px solid #d1d1d1;
  margin-left: 20px;
  padding-left: 15px;
  padding-right: 10px;
  color: #1d1d1d;
  font-size: 14px;
}

.input_con > span {
  padding-left: 15px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
  color: #51ccb4;
  font-weight: 500;
}
</style>
