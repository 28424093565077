<template>
  <div class="remark_item">
    <div class="user">
      <img class="avatar" :src="remark.headImg" />
      <span class="title">{{ remark.nickName }}</span>
    </div>
    <div class="content">{{ remark.content }}</div>
    <span class="date">{{ remark.timeStr }}</span>
  </div>
</template>

<script>
export default {
  props: {
    remark: {
      type: Object
    }
  }
};
</script>

<style scoped>
.remark_item {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.remark_item > .user {
  display: flex;
  align-items: center;
}

.user > .avatar {
  width: 30px;
  height: 30px;
  border-radius: 15px;
}

.user > .title {
  color: #adadad;
  font-size: 12px;
  margin-left: 5px;
}

.remark_item > .content {
  margin-left: 35px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 14px;
  color: #1d1d1d;
}

.remark_item > .date {
  color: #adadad;
  font-size: 12px;
  margin-left: 35px;
  margin-bottom: 20px;
}
</style>
